#item-dialog .cui-dialog-content {
    border-radius: 10px;
    height: 73%;
    min-width: 85%;
}
#item-dialog .cui-dialog-body {
    padding: 0
}
#item-dialog iframe {
    min-width: 100%;
    min-height: 100vh;
    border: 0;
    display: block;
}